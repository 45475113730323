import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';
import { Typography, Row, Col } from 'antd';

import {LinkedinFilled} from '@ant-design/icons';
import Core from "../../Core.js";

import './index.scss';

const { Title } = Typography;

export default class SpeakersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectLogin: false,
            data: [],
        };
    }

    componentDidMount() {
        if (Core.data().config.private == true && Core.data().login == null) {
            this.setState({ redirectLogin: true });
        } else {
            this.loadIntroduction();
        }

    }

    loadIntroduction() {
        this.setState({ loading: true });
        Core.apiData(`pages/speakers.json`, {
            onSuccess: (data) => {
                if (data.json) {
                    this.setState({
                        data: data.json,
                    });
                } else {
                    this.setState({ error: true, loading: false });
                    console.log("Schedule introduction is not a JSON!");
                }
            },
            onFail: (data) => {
                this.setState({ error: true, loading: false });
                console.log("Schedule introduction failed.", data);
            }
        });
    }

    render() {
        const { redirectLogin } = this.state;

        if (redirectLogin) {
            window.location.assign("/login");
        }

        return (
            <div className="speakers">
                <div className="content-title">
                    <Title>Oradores</Title>
                </div>
                <div className="content-body">
                    <Row>
                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <div>
                                <iframe id="ls_embed_1617099260" src="https://livestream.com/accounts/3288426/events/9577642/videos/219412770/player?width=640&height=360&enableInfo=false&defaultDrawer=&autoPlay=true&mute=false" width="640" height="360" frameborder="0" scrolling="no" allowfullscreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1617099260" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script>
                            </div>
                            <a target="_blank" href="https://www.linkedin.com/in/gleonhard/"><LinkedinFilled /> Gerd Leonhard</a>
                            <p>Futurista, humanista, orador, autor e realizador.<br/>“Pessoas, Planeta, Propósito e Prosperidade”.” É o lema de Gerd Leonhard. Orador em centenas de conferências nos últimos 20 anos. Trabalhou com empresas como a Microsoft, NBC, Visa, Google, Comissão Europeia, Audi e IBM, influenciando e inspirando milhões para “imaginar e criar um futuro melhor”</p>
                        </Col>
                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <div>
                                <iframe id="ls_embed_1617099313" src="https://livestream.com/accounts/3288426/events/9577642/videos/219412774/player?width=640&height=360&enableInfo=false&defaultDrawer=&autoPlay=true&mute=false" width="640" height="360" frameborder="0" scrolling="no" allowfullscreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1617099313" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script>
                            </div>
                            <a target="_blank" href="https://www.linkedin.com/in/nadim-habib-299168/"><LinkedinFilled /> Nadim Habib</a>
                            <p>Mestre em Economia pela London School of Economics e um comunicador nato. Nadim Habib é também consultor internacional nas áreas de estratégia, inovação e criatividade. É frequentemente convidado como orador. Com experiência internacional de gestão, foi CEO da Formação de Executivos da Nova SBE e da Angola Business School, colaborou com diversas multinacionais, incluindo agências criativas e de Relações Públicas.</p>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}