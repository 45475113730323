import React, { Component } from 'react';

import './index.scss';

export default class CookiesPoliciesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
            }
        };
    }
    
    componentDidMount() {
    }
    
    render() {
        return (
            <div className="temp-player"/>
        );
    }
}
