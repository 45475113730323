import React, { Component } from 'react';
import { Typography, Row, Col } from 'antd';

import Schedule from "../../containers/Schedule/index.jsx";

import './index.scss';

const { Title } = Typography;

export default class SchedulePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="schedule-page-container">
                <div class="content-title"><h1 class="ant-typography">Agenda prevista</h1></div>
                <div className="schedule-page">
                    <Row>
                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <div className="content-title">
                                <Title className="schedule__title">Manhã</Title>
                                <div className="schedule__item">
                                    <p>10h00 - Boas-vindas</p>
                                </div>
                                <div className="schedule__item">
                                    <p>10h05 - NOS NEXT GEN</p>
                                    <p className="schedule__speaker">Miguel Almeida</p>
                                    <p className="schedule__speaker">Margarida Nápoles</p>
                                </div>
                                <div className="schedule__item">
                                    <p>10h25 - O futuro é 5G</p>
                                    <p className="schedule__speaker">Manuel Ramalho Eanes</p>
                                    <p className="schedule__speaker">Luísa Jervell</p>
                                    <p className="schedule__speaker">Judite Reis</p>
                                    <p className="schedule__speaker">Rita Torres Baptista</p>
                                </div>
                                <div className="schedule__item">
                                    <p>11h05 - The next 10 years: 5G and beyond</p>
                                    <p className="schedule__speaker">Gerd Leonhard</p>
                                </div>
                                <div className="schedule__item">
                                    <p>11h35 - Pausa da manhã</p>
                                </div>
                                <div className="schedule__item">
                                    <p>11h50 - O futuro é Proximidade</p>
                                    <p className="schedule__speaker">Daniel Beato</p>
                                    <p className="schedule__speaker">João Ricardo Moreira</p>
                                    <p className="schedule__speaker">Pedro Abrantes</p>
                                    <p className="schedule__speaker">Raquel Vidigal</p>
                                </div>
                                <div className="schedule__item">
                                    <p>12h25 - O Futuro é Digital</p>
                                    <p className="schedule__speaker">Luis Nascimento</p>
                                    <p className="schedule__speaker">Nuno Schiappa</p>
                                    <p className="schedule__speaker">Filipe Santos</p>
                                    <p className="schedule__speaker">Isabel Ferreira</p>
                                </div>
                                <div className="schedule__item">
                                    <p>13h15 - Almoço</p>
                                </div>
                            </div>
                        </Col>
                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <div className="content-title">
                                <Title className="schedule__title">Tarde</Title>
                                <div className="schedule__item">
                                    <p>14h30 - Balanço da manhã</p>
                                </div>
                                <div className="schedule__item">
                                    <p>14h45 - O futuro é Sustentável</p>
                                    <p className="schedule__speaker">José Pedro Pereira da Costa</p>
                                    <p className="schedule__speaker">Filipa Carvalho</p>
                                    <p className="schedule__speaker">Diogo Serras Pereira</p>
                                    <p className="schedule__speaker">João Diogo Ferreira</p>
                                </div>
                                <div className="schedule__item">
                                    <p>15h15 - O futuro é Experiência</p>
                                    <p className="schedule__speaker">Jorge Graça</p>
                                    <p className="schedule__speaker">João Miguel Ferreira</p>
                                    <p className="schedule__speaker">Sofia Vaz Pires</p>
                                    <p className="schedule__speaker">Adriano Neves</p>
                                </div>
                                <div className="schedule__item">
                                    <p>15h50 - Lideranças do futuro</p>
                                    <p className="schedule__speaker">Nadim Habib</p>
                                </div>
                                <div className="schedule__item">
                                    <p>16h25 - Pausa da tarde</p>
                                </div>
                                <div className="schedule__item">
                                    <p>16h40 - O futuro Somos nós</p>
                                    <p className="schedule__speaker">Miguel Almeida</p>
                                    <p className="schedule__speaker">Isabel Borgas</p>
                                </div>
                                <div className="schedule__item">
                                    <p>17h30 - Fecho do dia</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
