import React, { Component } from 'react';
import { Layout } from 'antd';
import Register from '../../containers/Register';

import './index.scss';

export default class RegisterPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <Layout className="register-page">
              <main>
                <Register/>
              </main>
            </Layout>
        );
    }
}
