import React, { Component } from "react";
import ReactDOM from "react-dom";
//import { createBrowserHistory } from 'history';
//import ReactGA from 'react-ga';
//import history from "./history";

import { Layout, Menu, Spin } from 'antd';
import Burger from '@animated-burgers/burger-slip';
import '@animated-burgers/burger-slip/dist/styles.css';
import classNames from 'classnames';

import MainFooter from "./containers/Footer";
import { Redirect } from 'react-router-dom';

import MainPage from './pages/Main';
import TermsConditionPage from './pages/TermsCondition';
import PrivacyPoliciesPage from './pages/PrivacyPolicies';
import CookiesPoliciesPage from './pages/CookiesPolicies';
import SpeakersPage from './pages/Speakers';
import PlayerPage from './pages/Player';
import TempPlayerPage from './pages/TempPlayer';
import RegisterPage from './pages/Register';
import SchedulePage from './pages/Schedule';
import LoginPage from './pages/Login';
import LivePollResults from './pages/LivePollResults';

import Cookies from './containers/Cookies';
import GA from './components/Utils/GoogleAnalytics.js'

import Core from './Core';
import { getSocket, initSocket } from './socket';


import './App.scss';

import { UserOutlined } from '@ant-design/icons';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Welcome from "./pages/Welcome";

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loginLogoutTick: 0,
      defaultSelectedMenu: 'main',
      burgerMenu: false,
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.handleLogged = this.handleLogged.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    Core.onLogged = this.handleLogged;
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    if (window.location.pathname == '/') {
      this.setState({ defaultSelectedMenu: 'main' });
    } else {
      this.setState({ defaultSelectedMenu: 'none' });
    }
    if (Core.data().login && getSocket() == null) {
      initSocket(Core.data().login);
    }
    Core.menu.click = this.onMenuClick;
    this.loadConfig();
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log(prevState.location);
    //console.log(this.state.location);
    //console.log(history.location.pathname.toUpperCase());
    console.log(window.location.pathname.toUpperCase());
    //if(this.props.location !== prevProps.location ) {
    //    this.setState({ location: window.location.pathname.toUpperCase() })
    //  }
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    Core.menu.click = () => { };
  }


  updateWindowDimensions() {
    const { burgerMenu } = this.state;
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      burgerMenu: window.innerWidth > 800 ? false : burgerMenu
    });
  }

  onMenuClick(selectedMenu) {
    let { defaultSelectedMenu } = this.state;
    if (selectedMenu) {
      defaultSelectedMenu = selectedMenu;
    }
    this.setState({ burgerMenu: false, defaultSelectedMenu });
    //history.push(selectedMenu)
    window.scrollTo(0, 0);
  }


  loadConfig() {
    Core.apiData(`config.json`, {
      onSuccess: (data) => {
        if (data.json) {
          Core.data().config = data.json;
          document.title = data.json.site_title;
          this.setState({ loaded: true });
        } else {
          this.setState({ loaded: false });
          console.log("Config data is not a JSON!", data);
        }
      },
      onFail: (data) => {
        this.setState({ loaded: false });
        console.log("Config data failed!", data);
      }
    });
  }

  handleLogged() {
    const { loginLogoutTick } = this.state;
    this.setState({ loginLogoutTick: loginLogoutTick + 1 });
  }

  handleLogout() {
    const { loginLogoutTick } = this.state;
    Core.data().login = null;
    sessionStorage.clear();
    this.setState({ loginLogoutTick: loginLogoutTick + 1 });
    window.location.assign("/")
  }

  render() {
    const { burgerMenu, defaultSelectedMenu, loaded, logout } = this.state;
    if (loaded == false) {
      return (
        <Layout>
          <div className="loading">
            <Spin size="large" />
            <h1>A carregar...</h1>
          </div>
        </Layout>
      );
    }

    console.log("Core Data: ");
    console.log(Core.data());

    return (
      <Router>
        { GA.init() && <GA.RouteTracker />}
        <div className="page">
          <Layout>
            {(!Core.data().config.private || window.location.pathname.toUpperCase() !== '/LOGIN') ?
              <Header className={classNames({ 'header-burger-open': burgerMenu })}>
                <h1 className="header-title">
                  <Link to="/" onClick={() => this.onMenuClick('main')}>
                    <img src="/data/images/logo.png" />
                  </Link>
                </h1>
                <div className="header-burger">
                  <Burger isOpen={burgerMenu} onClick={() => { this.setState({ burgerMenu: !burgerMenu }); }} />
                </div>
                <div className={
                  classNames({
                    'menu': true,
                    'menu-burger-open': burgerMenu
                  })
                }>
                  <Menu
                    theme="dark"
                    mode={burgerMenu ? 'vertical' : 'horizontal'}
                    defaultSelectedKeys={[defaultSelectedMenu]}
                    selectedKeys={[defaultSelectedMenu]}>
                    {Core.data().config.no_home == false && Core.data().login ?
                      <Menu.Item key="main"><Link to="/" onClick={() => this.onMenuClick('main')}>Início</Link></Menu.Item>
                      : null}
                    {Core.data().login ?
                      <Menu.Item key="welcome"><Link to="/boas-vindas" onClick={() => this.onMenuClick('welcome')}>Boas-vindas</Link></Menu.Item>
                      : null}
                    {Core.data().config.speakers == true && Core.data().login ?
                      <Menu.Item key="speakers"><Link to="/speakers" onClick={() => this.onMenuClick('speakers')}>Oradores</Link></Menu.Item>
                      : null}
                    {Core.data().config.schedules == true && Core.data().login ?
                      <Menu.Item key="programa"><Link to="/programa" onClick={() => this.onMenuClick('programa')}>Agenda</Link></Menu.Item>
                      : null}
                    {Core.data().login && Core.data().config.no_home == false ?
                      <Menu.Item key="player" className="player-menu" ><Link to="/PU87hnF4CGFzm73e" onClick={() => this.onMenuClick('player')}>Evento</Link></Menu.Item> : null}
                    {/*  {Core.data().config.login == true || Core.data().config.register == true ?
                      <SubMenu className="right-input profile" title={<UserOutlined className="site-form-item-icon" />}>
                        {Core.data().config.login == true ? (
                          Core.data().login ?
                            <Menu.Item key="logout"><a onClick={this.handleLogout}>Logout</a></Menu.Item>
                            : <Menu.Item key="login"><Link to="/login" onClick={() => this.onMenuClick('login')}>Login</Link></Menu.Item>
                        ) : null}
                        {Core.data().config.register == true && Core.data().config.email_only == false ?
                          <Menu.Item key="registo"><Link to="/registo" onClick={() => this.onMenuClick('registo')}>Registo</Link></Menu.Item>
                          : null}
                      </SubMenu>
                      : null} */}
                    {/* <Menu.ItemGroup title="Perfil" className="profile-mobile">
                      {Core.data().login ?
                        <Menu.Item key="logout"><a onClick={this.handleLogout}>Logout</a></Menu.Item>
                        : <Menu.Item key="login"><Link to="/login" onClick={() => this.onMenuClick('login')}>Login</Link></Menu.Item>}
                      {Core.data().config.register == true ?
                        <Menu.Item key="registo"><Link to="/registo" onClick={() => this.onMenuClick('registo')}>Registo</Link></Menu.Item>
                        : null}
                    </Menu.ItemGroup> */}
                  </Menu>
                </div>
              </Header>
              : null}
            <Content>
              <Switch>
                <Route path="/termos-e-condicoes">
                  <TermsConditionPage />
                </Route>
                {Core.data().login ?
                  <Route path="/boas-vindas">
                    <Welcome />
                  </Route>
                  : null}
                <Route path="/politica-de-privacidade">
                  <PrivacyPoliciesPage />
                </Route>
                <Route path="/politica-de-cookies">
                  <CookiesPoliciesPage />
                </Route>
                {Core.data().config.speakers == true && Core.data().login ?
                  <Route path="/speakers">
                    <SpeakersPage />
                  </Route>
                  : null}
                <Route path="/player">
                  <TempPlayerPage />
                </Route>
                <Route path="/PU87hnF4CGFzm73e">
                  <PlayerPage />
                </Route>
                {Core.data().config.register == true ?
                  <Route path="/registo">
                    <RegisterPage />
                  </Route>
                  : null}
                {Core.data().config.schedules == true && Core.data().login ?
                  <Route path="/programa">
                    <SchedulePage />
                  </Route>
                  : null}
                <Route path="/livePollResults">
                  <LivePollResults />
                </Route>
                <Route path="/login/:token?" component={LoginPage} />
                <Route path="/">
                  <MainPage />
                </Route>
              </Switch>
            </Content>
            {(!Core.data().config.private || window.location.pathname.toUpperCase() !== '/LOGIN') ?
              <MainFooter />
              : null}
          </Layout>
        </div>
      </Router>
    );
  }
}
