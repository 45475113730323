import React, { Component } from "react";

import { Redirect } from 'react-router-dom';
import { Typography, Tabs, List, Divider, Row, Card, Col } from 'antd';
import Core from "../../Core.js";

import './index.scss';

const { Title } = Typography;
const { TabPane } = Tabs;

export default class ScheduleContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectLogin: false,
            loading: false,
            data: [],
            page: []
        };
    }

    componentDidMount() {
        if (Core.data().config.private == true && Core.data().login == null) {
            this.setState({ redirectLogin: true });
        } else {
            this.loadSchedule();
            this.loadIntroduction();
        }
    }

    loadIntroduction() {
        this.setState({ loading: true });
        Core.apiData(`pages/schedules.json`, {
            onSuccess: (data) => {
                if (data.json) {
                    this.setState({
                        page: data.json,
                    });
                    this.loadSchedule();
                } else {
                    this.setState({ error: true });
                    console.log("Schedule introduction is not a JSON!");
                }
            },
            onFail: (data) => {
                this.setState({ error: true });
                console.log("Schedule introduction failed.", data);
            }
        });
        this.setState({ loading: false });
    }

    loadSchedule() {
        Core.apiData(`schedules/list.json`, {
            onSuccess: (data) => {
                if (data.json) {
                    this.setState({
                        data: data.json,
                    });
                } else {
                    this.setState({ error: true });
                    console.log("Schedule data is not a JSON!");
                }
            },
            onFail: (data) => {
                this.setState({ error: true });
                console.log("Schedule data failed.", data);
            }
        });
    };

    render() {
        const { redirectLogin, data } = this.state;

        if (redirectLogin) {
          window.location.assign('/login')
        }

        const tabs = [];
        let tabListItems = [];
        let tabListItemsMeta = [];
        let currentDate = null;
        let currentTimeRange = null;
        let time = null;
        for (const schedule of data) {

            schedule.date = schedule.date.replace('2020-', '');
            schedule.date = schedule.date.split("-").reverse().join("/");

            if (currentDate == null) {
                currentDate = schedule.date;
            } else if (currentDate != schedule.date) {
                tabListItems.push(
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Title className="tab-avatar" level={4}>{currentTimeRange}</Title>}
                            title={""}
                            description={( <div className="schedule__item">{tabListItemsMeta}</div>)}
                        />
                    </List.Item>
                );

                tabs.push(
                    <TabPane tab={currentDate} key={currentDate}>
                        {tabListItems}
                    </TabPane>
                );
                currentDate = schedule.date;
                currentTimeRange =  null;
                tabListItems = [];
                tabListItemsMeta = [];
            }

            time = schedule.time.slice(0, -3);
            if (schedule.endtime != null && schedule.endtime != "") {
                time = schedule.time.slice(0, -3) + " - " + schedule.endtime.slice(0, -3);
            }

            if ( currentTimeRange == null || ( currentTimeRange != null &&  currentTimeRange == time) ) {
                currentTimeRange = time;

                tabListItemsMeta.push(<h4 className="schedule__title">{schedule.title}</h4>);

                const speakers = schedule.speakers.map((speaker) => <li key={speaker.uid} className="schedule__speaker">{speaker.name} {speaker.title ? `- ${speaker.title}` : ""}</li>);
                tabListItemsMeta.push(speakers);
            } else if (currentTimeRange != time) {
                tabListItems.push(
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Title className="tab-avatar" level={4}>{currentTimeRange}</Title>}
                            title={""}
                            description={( <div className="schedule__item">{tabListItemsMeta}</div>)}
                        />
                    </List.Item>
                );

                tabListItemsMeta = [];
                tabListItemsMeta.push(<h4 className="schedule__title">{schedule.title}</h4>);

                const speakers = schedule.speakers.map((speaker) => <li key={speaker.uid} className="schedule__speaker">{speaker.name} - {speaker.title}</li>);
                tabListItemsMeta.push(speakers)

                currentTimeRange = time;
            }
        }

        tabListItems.push(
            <List.Item>
                <List.Item.Meta
                    avatar={<Title className="tab-avatar" level={4}>{currentTimeRange}</Title>}
                    title={""}
                    description={( <div className="schedule__item">{tabListItemsMeta}</div>)}
                />
            </List.Item>
        );

        if (currentDate != null && tabListItems.length > 0) {
            tabs.push(
                <TabPane tab={currentDate} key={currentDate}>
                    {tabListItems}
                </TabPane>
            );
        }

        return (
            <div className="schedule">
                <div className="schedule__content">
                    {this.state.page.content}
                </div>
                <Tabs defaultActiveKey="1" className="schedule__tabs">
                    {tabs}
                </Tabs>
            </div>
        );
    }
}
