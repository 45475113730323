import React, { Component, } from 'react';
import { Row, Col, Typography } from 'antd';

import Core from '../../Core';

import './index.scss';

const { Title } = Typography;

export default class Welcome extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="welcome-page">
                <Row>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                        <div className="content-title">
                            <Title className="welcome__title-first">Olá {Core.data().login.name}, bem-vinda(o)!</Title>
                        </div>
                    </Col>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} className="content-description">
                        <p>Hoje vamos falar de futuro.</p>
                        <p>Antes de começarmos o nosso dia/o nosso evento, queremos partilhar contigo algumas dicas.</p>
                        <div className="content-title">
                            <Title className="welcome__title-third">Preparada(o)?</Title>
                        </div>
                    </Col>
                </Row>

                <Row className="welcome-info">
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                        <div className="content-title">
                            <Title className="welcome__title-third">Set…</Title>
                        </div>
                        <Row 
                            className="info-item" 
                            type="flex"   
                            style={{ alignItems: "center" }}
                            justify="center"
                            gutter={10}
                        >
                            <Col xl={{ span: 3 }} lg={{ span: 4 }} md={{ span: 5 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <img src="./data/images/ReuniaoGeralNos/luz.png" width="auto" height="80"/>
                            </Col>
                            <Col xl={{ span: 21 }} lg={{ span: 20 }} md={{ span: 19 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <p>Escolhe o teu local mais sossegado, confortável e luminoso.</p>
                            </Col>
                        </Row>
                        <Row 
                            className="info-item" 
                            type="flex"   
                            style={{ alignItems: "center" }}
                            justify="center"
                            gutter={10}
                        >
                            <Col xl={{ span: 3 }} lg={{ span: 4 }} md={{ span: 5 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <img src="./data/images/ReuniaoGeralNos/confortavel.png" width="auto" height="70"/>
                            </Col>
                            <Col xl={{ span: 21 }} lg={{ span: 20 }} md={{ span: 19 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <p>Senta-te confortavelmente, mas não te esqueças de te levantares e movimentares de vez em quando. O teu corpo agradece.</p>
                            </Col>
                        </Row>
                        <Row 
                            className="info-item" 
                            type="flex"   
                            style={{ alignItems: "center" }}
                            justify="center"
                            gutter={10}
                        >
                            <Col xl={{ span: 3 }} lg={{ span: 4 }} md={{ span: 5 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <img src="./data/images/ReuniaoGeralNos/agua.png" width="auto" height="80"/>
                            </Col>
                            <Col xl={{ span: 21 }} lg={{ span: 20 }} md={{ span: 19 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <p>Mantém uma garrafa de água junto a ti para te hidratares.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                        <div className="content-title">
                            <Title className="welcome__title-third">Go!</Title>
                        </div>
                        <Row 
                            className="info-item" 
                            type="flex"   
                            style={{ alignItems: "center" }}
                            justify="center"
                            gutter={10}
                        >
                            <Col xl={{ span: 3 }} lg={{ span: 4 }} md={{ span: 5 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <img src="./data/images/ReuniaoGeralNos/questoes.png" width="auto" height="70"/>
                            </Col>
                            <Col xl={{ span: 21 }} lg={{ span: 20 }} md={{ span: 19 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <p>Participa ativamente. Usa o chat para partilhares comentários.</p>
                                <p>Este é um evento corporativo e um espaço partilhado com todos, vamos torna-lo especial.</p>
                                <p>Responde às poll que vão acontecendo durante o dia.</p>
                            </Col>
                        </Row>
                        <Row 
                            className="info-item" 
                            type="flex"   
                            style={{ alignItems: "center" }}
                            justify="center"
                            gutter={10}
                        >
                            <Col xl={{ span: 3 }} lg={{ span: 4 }} md={{ span: 5 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <img src="./data/images/ReuniaoGeralNos/confidencial.png" width="auto" height="70"/>
                            </Col>
                            <Col xl={{ span: 21 }} lg={{ span: 20 }} md={{ span: 19 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <p>Se quiseres, partilha nas redes sociais os conteúdos que vamos disponibilizar para o efeito.</p>
                            </Col>
                        </Row>
                        <Row 
                            className="info-item" 
                            type="flex"   
                            style={{ alignItems: "center" }}
                            justify="center"
                            gutter={10}
                        >
                            <Col xl={{ span: 5 }} lg={{ span: 5 }} md={{ span: 5 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <div className="content-title" style={{ paddingTop: 35 }}>
                                    <Title className="welcome__title-third">Vamos a isto?</Title>
                                </div>
                            </Col>
                            <Col xl={{ span: 19 }} lg={{ span: 19 }} md={{ span: 19 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                
                            </Col>
                        </Row>
                    </Col>
                    <Col offset={12} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>

                    </Col>
                </Row>
            </div>
        );
    }
}
