import React, { Component } from 'react';
import {Typography} from 'antd';

import Core from "../../Core.js";

import './index.scss';

const { Title } = Typography;

export default class TermsConditionPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {
                title: '',
                body: ''
            }
        };
    }

    componentDidMount() {
        this.loadContent();
    }
    
    loadContent() {
        this.setState({ loading: true });
        Core.apiData(`contents/terms-condition.json`, {
            onSuccess: (data) => {
                if (data.json) {
                    this.setState({
                        data: data.json,
                        loading: false
                    });
                } else {
                    this.setState({ error: true, loading: false });
                    console.log("Terms Condition is not a JSON!");
                }
            },
            onFail: (data) => {
                this.setState({ error: true, loading: false });
                console.log("Terms Condition introduction failed.", data);
            }
        });
    }

    render() {
        const {data} = this.state;
        return (
            <div className="terms-condition">
              <div className="content-title">
                <Title>{data.title}</Title>
              </div>
              <div className="content-body" dangerouslySetInnerHTML={{__html: data.body}} />
            </div>
        );
    }
}
